import { redirect, type LoaderFunctionArgs } from "@remix-run/node"
import { getAuthFromRequest } from "@/features/auth.server"

export async function loader({ request }: LoaderFunctionArgs) {
  await getAuthFromRequest(request)

  return redirect("/amocrm-moysklad")
}

export default function IndexPage() {
  return null
}
